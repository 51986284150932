import { provideIntoWindow } from "@b2c/core/app/default/provide-into-window.js";
import { brandConfig } from "../index.js";
import { $_ALTENARCONFIG } from "./../altenar.mjs";

const pgwUrl = 'https://app.payment-gateway.io';
const merchantKey = 'd8298a74-e5c8-4661-89b2-c15d68804da6';
const productionConfig = {
    pgwConfig: {
        pgwUrl: pgwUrl,
        scriptLoaderUrl: pgwUrl + '/api/widget/loader?merchantKey=' + merchantKey,
        merchantKey: merchantKey,
        pgwPaymentLightSvg: pgwUrl + '/assets/logos/light/large/',
        pgwPaymentDarkSvg: pgwUrl + '/assets/logos/dark/large/',
    }
};

provideIntoWindow("$_ALTENARCONFIG", $_ALTENARCONFIG);

export const prod = provideIntoWindow(
    "$_CONFIG",
    Object.assign({}, brandConfig, productionConfig)
);
