const aliases = {
  home: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.home',
  },
  promo: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos',
  },
  login: {
    page: false,
    name: 'login',
  },
  registration: {
    page: false,
    name: 'registration',
  },
  paynplay: {
    page: true,
    name: 'app.root.layer1.layer2.registration-bank',
  },
  deposit: {
    page: false,
    name: 'cashbox',
    private: true,
  },
  withdraw: {
    page: false,
    name: 'cashbox',
    private: true,
    content: {
      action: 'withdrawal',
    },
  },
  realGame: {
    page: true,
    name: 'app.root.game.real'
  },
  santa_slope: {
    page: true,
    name: 'app.root.game.real',
    params: { name: 'santas-slope' }
  },
  notFound: {
    page: true,
    name: 'app.root.layer1.layer2.not-found',
  },
  missedData: {
    page: true,
    name: 'app.root.missed-data',
  },

  availableBonuses: {
    page: true,
    name: 'app.root.layer1.layer2.account.staff',
    private: true,
  },

  bonus_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: { name: 'cash-crab' },
  },
  cash_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: { name: 'cash_crab' },
  },
  claw: {
    page: true,
    name: 'app.root.claw_games.gamepage',
  },
  balance: {
    page: true,
    name: 'app.root.layer1.layer2.account.balance',
  },
  terms: {
    page: true,
    name: 'app.root.layer1.layer2.rules',
  },
  profile: {
    page: true,
    name: 'app.root.layer1.layer2.account.staff',
    private: true,
  },
  contact: {
    page: true,
    name: 'app.root.layer1.layer2.contact-us',
  },
  live: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.games',
    params: {
      name: 'live-casino',
      collection: 'games',
    },
  },
  games: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.games',
    params: {
      name: 'all-games',
      collection: 'games',
    },
  },
  dropwins: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.games',
    params: {
      name: 'dropwins',
      collection: 'games',
    },
  },
  evolution: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'evolution',
    },
  },
  pragmatic: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'pragmatic',
    },
  },
  threeoaks: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'threeoaks',
    },
  },
  spinomenal: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'spinomenal',
    },
  },
  ela: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'ela',
    },
  },
  playngo: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'playngo',
    },
  },
  relax: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'relax',
    },
  },
  skywind: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'skywind',
    },
  },
  netent: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'netent',
    },
  },
  pushgaming: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'pushgaming',
    },
  },
  playtech: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'playtech',
    },
  },
  quickspin: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'quickspin',
    },
  },
  redtiger: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    params: {
      name: 'redtiger',
    },
  },
  halloween: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.tournaments',
  },
  tournaments: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.tournaments',
  },
  history: {
    page: true,
    name: 'app.root.layer1.layer2.account.history',
  },
  // CHRISTMAS
  christmasTournament: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.tournaments',
  },
  promotions: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.promos',
  },
};

export { aliases };
